import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import aboutMe from "./Images/EnesAtacanAboutMe.JPEG";
import "./Style/Buttons.css";
import "./Style/Delay.css";
import Contact from "./ContactForm";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Links from "./Links";
import Helmet from "react-helmet";

function AboutMe() {
  const { InstagramLink, GitHubLink, LinkedinLink } = Links;
  const [showText, setShowText] = useState(false);
  const pageTitle = "Enes ATACAN | About Me";
  const pageDescription =
    "Learn more about Enes ATACAN and check out his mini autobiography";
  const keywords = "Enes Atacan, Hakkımda, About Me,Instagram";

  useEffect(() => {
    // ComponentDidMount benzeri davranış için useEffect kullanıyoruz
    const timer = setTimeout(() => {
      setShowText(true); // .1 saniye sonra metni göster
    }, 100);

    // Temizleme fonksiyonu: bileşen unmount olduğunda zamanlayıcıyı temizle
    return () => clearTimeout(timer);
  }, []); // [] içindeki boş dependency array sadece bir kere çalıştırılmasını sağlar
  return (
    <Box className={`delay ${showText ? "show" : ""}`}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="robots" content="noindex, follow" />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Grid container height={"100vh"}>
        <Grid mt={15} mb={10} style={{ bgcolor: "#f3f4f1" }} xs={12} lg={6}>
          <div
            style={{
              width: "60%",
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <Typography variant="h2">About Me</Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    marginRight: 20,
                    width: "20%",
                    backgroundColor: "black",
                  }}
                />
                <Typography variant="body2">Enes ATACAN</Typography>
              </div>
            </div>
            <Typography variant="body2">
              Welcome to getting to know Enes and his stories from the past...
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} bgcolor={"#f3f4f1"}>
          <img
            src={aboutMe}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              zIndex: 0,
              objectFit: "cover",
              opacity: 0.9,
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
            }}
            alt="Enes Atacan About Me"
          />
        </Grid>
      </Grid>

      <Grid height={"50%"} container bgcolor={"#f3f4f1"}>
        <Grid
          textAlign={"center"}
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ bgcolor: "#f3f4f1" }}
          xs={12}
          lg={12}
        >
          <Stack py={5} width={"60%"}>
            <Typography color={"#5f8680"} variant="h4">
              Hi,
            </Typography>
            <Typography mt={2} variant="body2">
              In September 2001, I was born in Pazar district of Rize as the
              third and youngest son of Zeki and Fatma. I am the youngest member
              of a family with three children. I successfully completed my
              primary, secondary and high school education in Rize. During these
              years, I took part in many social activities and won awards by
              representing myself and our school in various sports branches.
            </Typography>
            <Typography my={2} variant="body2">
              After my high school graduation, I studied intensively in Trabzon
              for a year in order to gain the 'Management Information Systems'
              department I dreamed of. As a result of these studies, I was
              entitled to enter the Management Information Systems department at
              Izmir Democracy University.
            </Typography>
            <Typography mb={2} variant="body2">
              Most of my life has been spent learning. In this world where there
              is no limit to learning, I still continue to learn and explore,
              which is my biggest hobby. We cannot know what the future will
              bring, but I believe that my current determination to work and my
              motivation to improve myself will be beneficial to both me and you
              who read this article.
            </Typography>
            <Typography mb={2} variant="body2">
              If you have any questions or queries, you can follow me on my
              personal social media accounts below or send me a message via the
              form.
            </Typography>
            <IconButton target="blank" href={InstagramLink}>
              <InstagramIcon color="black" />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <Grid height={"100%"} pt={5} container bgcolor={"#e7e9e2"}>
        <Grid
          item
          xs={12}
          lg={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Typography style={{ color: "#557772" }} variant="h4">
            Contact Me
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />
          <Typography variant="body2" sx={{ letterSpacing: "0.1em" }}>
            I look forward to getting in touch with you.
          </Typography>
          <Typography
            width={"80%"}
            textAlign={"center"}
            variant="body2"
            mt={1}
            sx={{ letterSpacing: "0.1em" }}
          >
            You can send me a message via the form below or you can contact me
            by following me on my social media accounts.
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />

          <Stack spacing={5} direction={"row"} mt={3}>
            <IconButton target="blank" href={GitHubLink}>
              <GitHubIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={LinkedinLink}>
              <LinkedInIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={InstagramLink}>
              <InstagramIcon color="black" />
            </IconButton>
          </Stack>
          <Contact />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AboutMe;
