import React, { useState } from "react";
import { Box, Grid, TextField, MenuItem, Button } from "@mui/material";
import emailjs from "@emailjs/browser";
import "./Style/Buttons.css";

const countryCodes = [
  { code: "+90", flag: "🇹🇷" },
  { code: "+1", flag: "🇺🇸" },
  { code: "+33", flag: "🇫🇷" },
  { code: "+91", flag: "🇮🇳" },
  { code: "+", flag: "🌍" },
];

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState(countryCodes[0].code); // Default to the first country code
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const serviceId = "service_xqcsmhd";
    const templateId = "template_hveisdo";
    const publicKey = "kf4t54C_ResvVeOz7";

    const templateParams = {
      from_name: name,
      from_email: email,
      from_code: code,
      from_phone: phone,
      to_name: "Enes ATACAN",
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        alert("Email sent successfully!");
        setName("");
        setEmail("");
        setCode(countryCodes[0].code);
        setPhone("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("Error sending email:");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleCountryCodeChange = (event) => {
    setCode(event.target.value);
  };

  return (
    <Box marginY={2} marginX={"auto"}>
      <Box sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                type="name"
                onChange={(e) => setName(e.target.value)}
                label="Name"
                value={name}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                type="email"
                label="E-Mail"
                value={email}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                label="Country Code"
                value={code}
                onChange={handleCountryCodeChange}
                fullWidth
                required
              >
                {countryCodes.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.flag} ({option.code})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={10}>
              <TextField
                name="phone"
                type="number"
                value={phone}
                label="Phone"
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="message"
                label="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                rows={4}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className="contactButton"
                variant="contained"
                type="submit"
                disabled={submitting}
              >
                {submitting ? "Sending..." : "Send"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}

export default Contact;
