import Header from "./Components/Header";
import Tasks from "./Components/Tasks";
import Footer from "./Components/Footer";
import FinishTask from "./Components/FinishTask";
import TrashedTask from "./Components/TrashedTask";
import { Stack, Typography } from "@mui/material";
import Helmet from "react-helmet";
import { useState } from "react";

function App() {
  const [tasks, setTasks] = useState([]);
  const [removeTaskCount, setRemoveTaskCount] = useState(0);
  const [complateTaskCount, setComplateTaskCount] = useState(0);
  const [finishTasks, setFinishTasks] = useState([]);
  const [trashTasks, setTrashTasks] = useState([]);

  const handleTaskAdd = (newTask) => {
    setTasks([...tasks, newTask]);
  };

  const handleDelete = (index) => {
    const deletedTasks = tasks.filter((task, i) => i !== index);
    const trashedTasks = tasks.find((task, i) => i === index);
    setTasks(deletedTasks);
    setRemoveTaskCount(removeTaskCount + 1);
    setTrashTasks([...trashTasks, trashedTasks]);
  };
  const handleComplate = (index) => {
    const complateTasks = tasks.filter((task, i) => i !== index);
    const finishedTasks = tasks.find((task, i) => i === index);
    setTasks(complateTasks);
    setComplateTaskCount(complateTaskCount + 1);
    setFinishTasks([...finishTasks, finishedTasks]);
  };
  const pageTitle = "Enes ATACAN | To Do App";
  const pageDescription =
    "Check out Enes ATACAN's projects with ReactJS: To do app";

  return (
    <div
      style={{ scale: "1", height: "100vh", marginTop: "120px" }}
      className="App"
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Stack
        mx={"auto"}
        sx={{ backgroundColor: "red", width: "30%", borderRadius: "16px" }}
      >
        <Typography color={"white"} padding={3} textAlign={"center"}>
          For a more accurate design, opt for the desktop view...
        </Typography>
      </Stack>

      <Header onTaskAdd={handleTaskAdd} />
      <Tasks
        tasks={tasks}
        setTasks={setTasks}
        onTaskDelete={handleDelete}
        onTaskComplate={handleComplate}
      />
      <FinishTask finishTasks={finishTasks} />
      <TrashedTask trashTasks={trashTasks} />
      <Footer
        pendingTasksCount={tasks.length}
        deletedTasksCount={removeTaskCount}
        complatedTaskCount={complateTaskCount}
      />
    </div>
  );
}

export default App;
