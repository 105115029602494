import React from "react";

function FinishTask({ finishTasks }) {
  return (
    <div className="mainSection">
      <div className="title">
        <h3>Completed Tasks:</h3>
        <hr />
      </div>
      <div className="tasks">
        {finishTasks.map((task, index) => (
          <div key={index} className="task">
            {task}
            <div style={{ color: "gray", cursor: "default" }}>Tamamlandı</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FinishTask;
