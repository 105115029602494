import React from "react";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Grid, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { useEffect, useState } from "react";
import "./Style/Delay.css";

function Works() {
  const [showText, setShowText] = useState(false);
  const pageTitle = "Enes ATACAN | Works";
  const pageDescription =
    "Check out Enes ATACAN's projects with ReactJS: To do app, weather app";
  const keywords =
    "Enes Atacan, Jobs, Projects, Projeler, React, To do app, weather app";

  useEffect(() => {
    // ComponentDidMount benzeri davranış için useEffect kullanıyoruz
    const timer = setTimeout(() => {
      setShowText(true); // .1 saniye sonra metni göster
    }, 100);

    // Temizleme fonksiyonu: bileşen unmount olduğunda zamanlayıcıyı temizle
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Box className={`delay ${showText ? "show" : ""}`}>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="robots" content="noindex, follow" />
          <meta name="keywords" content={keywords} />
        </Helmet>
        <Stack height={"100px"}></Stack>
        <Stack display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography variant="h2">Works</Typography>
          <Typography width={"60%"} mt={3} textAlign={"center"} variant="body2">
            I work on various projects to improve myself, for example a to-do
            list app like every developer does, or a simple number guessing
            game. These projects are great opportunities to consolidate my
            skills and learn new techniques. Keep scrolling and check out other
            projects
          </Typography>
          <Typography width={"60%"} mt={3} textAlign={"center"} variant="body2">
            On this page, there are projects I made using only the knowledge I
            learned with React JS. If you want to check out my work on js or ts,
            you can check it out on GitHub
          </Typography>
        </Stack>
        <Stack my={5}>
          <Grid container display={"flex"} direction={"row"} xs={12}>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">To Do List</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                In this project, I provide the user with a to-do list that can
                be used in everyday life, with options to add a new task,
                complete the task, delete the task and edit the (not yet
                completed) task. My goal was to test how an activity works and
                how the functions that affect each other work. (The project is
                not tied to any storage).
              </Typography>
              <Link to={"/todo"} target="_blank">
                <button className="outlinedButton">Browse</button>
              </Link>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Weather App</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                In this project; how to make a request to an API. I aimed to try
                how to return successful or unsuccessful messages to the user
                and how to reflect the operations performed as a result of the
                request on the screen... (I will add the weekly weather forecast
                and design to my project in the following stages)
              </Typography>
              <Link to={"/weather"} target="_blank">
                <button className="outlinedButton">Browse</button>
              </Link>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">X O X Game</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                It can be among my projects waiting to be completed or in the
                algorithm design phase. I need some time and maybe some
                experience to complete it. Please stay tuned...
              </Typography>
              <Link>
                <Button disabled className="outlinedButton">
                  Coming Soon
                </Button>
              </Link>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Digital Clock</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                It can be among my projects waiting to be completed or in the
                algorithm design phase. I need some time and maybe some
                experience to complete it. Please stay tuned...
              </Typography>
              <Button disabled className="outlinedButton">
                Coming Soon
              </Button>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Number Estimated</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                It can be among my projects waiting to be completed or in the
                algorithm design phase. I need some time and maybe some
                experience to complete it. Please stay tuned...
              </Typography>
              <Link>
                <Button disabled className="outlinedButton">
                  Coming Soon
                </Button>
              </Link>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Odd and Even</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                It can be among my projects waiting to be completed or in the
                algorithm design phase. I need some time and maybe some
                experience to complete it. Please stay tuned...
              </Typography>
              <Link>
                <Button disabled className="outlinedButton">
                  Coming Soon
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
}

export default Works;
