import React from "react";
import "./Style/Tasks.css";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
function Tasks({ tasks, onTaskDelete, onTaskComplate }) {
  return (
    <div className="mainSection">
      <div>
        <div className="title">
          <h3>Tasks:</h3>
          <hr />
        </div>
        <div className="tasks">
          {tasks.map((task, index) => (
            <div className="task" style={{ marginRight: "auto" }} key={index}>
              {task}
              <div>
                <button
                  onClick={() => onTaskComplate(index)}
                  style={{ marginLeft: "auto", marginRight: 2 }}
                >
                  <CheckIcon />
                </button>
                <button style={{ marginLeft: "auto", marginRight: 2 }}>
                  <EditIcon />
                </button>
                <button
                  onClick={() => onTaskDelete(index)}
                  style={{ marginLeft: "auto" }}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tasks;
