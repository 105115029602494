import React from "react";
import { Box, Typography } from "@mui/material";

function Footer() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  return (
    <Box textAlign={"center"} p={2} color={"white"} bgcolor={" #5d644f"}>
      <Typography variant="body2">
        &copy; {year} Enes Atacan. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
