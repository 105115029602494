import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ContactMe from "./Images/ContactMe.png";
import "./Style/Buttons.css";
import "./Style/Delay.css";
import ContactForm from "./ContactForm";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Links from "./Links";
import Helmet from "react-helmet";

function Contact() {
  const { InstagramLink, GitHubLink, LinkedinLink } = Links;
  const [showText, setShowText] = useState(false);
  const pageTitle = "Enes ATACAN | Contact";
  const pageDescription =
    "To contact Enes ATACAN, you can fill out the contact form or reach him via his social media channels. ";
  const keywords =
    "Enes Atacan, Contact Me, Bana Ulaşın, Personal Website, Kişisel Web Site,Frontend Developer, Instagram, Github, Linkedin";

  useEffect(() => {
    // ComponentDidMount benzeri davranış için useEffect kullanıyoruz
    const timer = setTimeout(() => {
      setShowText(true); // .1 saniye sonra metni göster
    }, 100);

    // Temizleme fonksiyonu: bileşen unmount olduğunda zamanlayıcıyı temizle
    return () => clearTimeout(timer);
  }, []); // [] içindeki boş dependency array sadece bir kere çalıştırılmasını sağlar
  return (
    <Box className={`delay ${showText ? "show" : ""}`}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Grid container height={"100vh"}>
        <Grid pt={5} style={{ bgcolor: "#f3f4f1" }} xs={12} lg={6}>
          <div
            style={{
              width: "60%",
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <Typography variant="h2">Contact Me</Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    marginRight: 20,
                    width: "20%",
                    backgroundColor: "black",
                  }}
                />
                <Typography variant="body2">Enes ATACAN</Typography>
              </div>
            </div>
            <Typography variant="body2">
              Hello, we can talk about many things together. Please feel free to
              contact me for any comments, suggestions or curiosities.
            </Typography>
            <Stack spacing={2} direction={"row"} mt={3}>
              <IconButton target="blank" href={GitHubLink}>
                <GitHubIcon color="black" />
              </IconButton>

              <IconButton target="blank" href={LinkedinLink}>
                <LinkedInIcon color="black" />
              </IconButton>

              <IconButton target="blank" href={InstagramLink}>
                <InstagramIcon color="black" />
              </IconButton>
            </Stack>
          </div>
        </Grid>
        <Grid zIndex={-5} item xs={12} lg={6} bgcolor={"#f3f4f1"}>
          <img
            src={ContactMe}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              zIndex: -1,
              objectPosition: "right center",
              objectFit: "cover",
              opacity: 0.95,
              boxShadow: "5px 0px 5px rgba(0, 0, 0, 0.5)",
            }}
            alt="Enes Atacan Contact Me"
          />
        </Grid>
      </Grid>

      <Grid height={"30vh"} container bgcolor={"#f3f4f1"}>
        <Grid
          textAlign={"center"}
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ bgcolor: "#f3f4f1" }}
          xs={12}
          lg={12}
        >
          <Typography color={"#5f8680"} variant="h4">
            Contact About Services
          </Typography>
          <Typography my={2} variant="body2">
            You can contact me at any time via the form below for the web
            solutions you want to receive service.
          </Typography>
        </Grid>
      </Grid>
      <Grid height={"100%"} pt={5} container bgcolor={"#e7e9e2"}>
        <Grid
          item
          xs={12}
          lg={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Typography style={{ color: "#557772" }} variant="h4">
            Contact Me
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />
          <Typography variant="body2" sx={{ letterSpacing: "0.1em" }}>
            I look forward to getting in touch with you.
          </Typography>
          <Typography
            width={"80%"}
            textAlign={"center"}
            variant="body2"
            mt={1}
            sx={{ letterSpacing: "0.1em" }}
          >
            You can send me a message via the form below or you can contact me
            by following me on my social media accounts.
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />

          <Stack spacing={5} direction={"row"} mt={3}>
            <IconButton target="blank" href={GitHubLink}>
              <GitHubIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={LinkedinLink}>
              <LinkedInIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={InstagramLink}>
              <InstagramIcon color="black" />
            </IconButton>
          </Stack>
          <ContactForm />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Contact;
