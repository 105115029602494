import React from "react";
import { useState, useEffect } from "react";
import "./Style/Header.css";

function Header({ onTaskAdd }) {
  const [task, setTask] = useState("");
  const handleChange = (e) => {
    setTask(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (task.trim() !== "") {
      onTaskAdd(task);
      console.log("Task:", task);
      setTask("");
    }
  };
  return (
    <div>
      <h2 style={{ justifyContent: "center" }}>My Todo List</h2>
      <form onSubmit={handleSubmit}>
        <div className="formBorder">
          <input
            type="text"
            placeholder="Görev Giriniz"
            value={task}
            onChange={handleChange}
          />
          <button>Add</button>
        </div>
      </form>
    </div>
  );
}

export default Header;
