import React from "react";
import "./Style/Footer.css";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
function Footer({ pendingTasksCount, deletedTasksCount, complatedTaskCount }) {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }} className="bg">
        <div>
          <AccessTimeIcon sx={{ marginTop: 3 }} />
          <h3>Pending</h3>
          <h4>{pendingTasksCount}</h4>
        </div>
        <span
          style={{
            justifyContent: "center",
            border: "1px solid",
            backgroundColor: "white",
            color: "white",
            height: "200px",
          }}
        ></span>
        <div>
          <CheckIcon sx={{ marginTop: 3 }} />
          <h3>Completed</h3>
          <h4>{complatedTaskCount}</h4>
        </div>
        <span
          style={{
            border: "1px solid",
            backgroundColor: "white",
            color: "white",
            height: "200px",
          }}
        ></span>
        <div>
          <DeleteIcon sx={{ marginTop: 3 }} />
          <h3>Deleted</h3>
          <h4>{deletedTasksCount}</h4>
        </div>
      </div>
    </>
  );
}

export default Footer;
